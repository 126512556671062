// Copyright 2020-2022 the Kubeapps contributors.
// SPDX-License-Identifier: Apache-2.0

import {
  ClarityIcons as Icons,
  angleIcon,
  applicationsIcon,
  backupRestoreIcon,
  bundleIcon,
  checkCircleIcon,
  circleArrowIcon,
  circleIcon,
  clusterIcon,
  copyToClipboardIcon,
  deployIcon,
  exclamationCircleIcon,
  exclamationTriangleIcon,
  eyeHideIcon,
  eyeIcon,
  fileGroupIcon,
  fileIcon,
  heartIcon,
  helpIcon,
  infoCircleIcon,
  libraryIcon,
  moonIcon,
  networkGlobeIcon,
  plusCircleIcon,
  refreshIcon,
  rewindIcon,
  searchIcon,
  successStandardIcon,
  sunIcon,
  timesCircleIcon,
  timesIcon,
  trashIcon,
  uploadCloudIcon,
} from "@cds/core/icon";
import "@cds/core/icon/register.js";
import "@clr/ui/clr-ui.min.css"; // light clarity UI theme

Icons.addIcons(
  angleIcon,
  applicationsIcon,
  backupRestoreIcon,
  bundleIcon,
  checkCircleIcon,
  circleArrowIcon,
  circleIcon,
  clusterIcon,
  copyToClipboardIcon,
  deployIcon,
  exclamationCircleIcon,
  exclamationTriangleIcon,
  eyeHideIcon,
  eyeIcon,
  fileGroupIcon,
  fileIcon,
  heartIcon,
  helpIcon,
  infoCircleIcon,
  libraryIcon,
  moonIcon,
  networkGlobeIcon,
  plusCircleIcon,
  refreshIcon,
  rewindIcon,
  searchIcon,
  successStandardIcon,
  sunIcon,
  timesCircleIcon,
  timesIcon,
  trashIcon,
  uploadCloudIcon,
  uploadCloudIcon,
);
const CSSSelector: React.FC = () => null;

export default CSSSelector;
